import React from "react";
import PropTypes from "prop-types";
import Layout from "../components/Layout/Layout";
import BlogListCard from "../components/BlogListCard";
import { graphql } from "gatsby";

import styled from "styled-components";

const ArticleTitle = styled.div`
	width: 100%;
	overflow: hidden;
	position: relative;
	margin-top: 50px;
	font-weight: 700;

	h1 {
		position: relative;
		display: inline-block;
		letter-spacing: -0.16px;
		padding: 0 30px 0 0;
		line-height: 28px;
		font-size: 28px;
		font-weight: 500;
		text-transform: none;

		:after {
			content: "";
			display: block;
			height: 6px;
			position: absolute;
			top: 50%;
			margin-top: -3px;
			width: 1260px;
			left: 100%;
			background: #000;
		}
	}

	@media (max-width: 960px) {
		h1 {
			font-size: 1.5rem;
		}
	}
	@media (min-width: 320px) and (max-width: 480px) {
		h1 {
			font-size: 1rem;
		}
	}
`;

const BlogCard = styled.div`
	display: flex;
	flex-flow: row wrap;
	align-items: flex-start;
	width: 1260px;
	margin: 0 auto;

	@media (min-width: 1024px) and (max-width: 1280px) {
		width: 95%;
	}

	@media (max-width: 960px) {
		width: 95%;
	}
`;

const PostList = ({ data }) => {
	return (
		<Layout>
			<BlogCard>
				<ArticleTitle>
					<h1>News & Events</h1>
				</ArticleTitle>
				{data.allWordpressPost.edges.map((item) => {
					return (
						<React.Fragment key={item.node.wordpress_id}>
							<BlogListCard
								blogLink={`/articles/${item.node.slug}`}
								blogImage={item.node.featured_media.source_url}
								blogImageAlt={item.node.featured_media.alt_text}
								blogTitle={item.node.title}
								blogExcerpt={item.node.excerpt}
							/>
						</React.Fragment>
					);
				})}
			</BlogCard>
		</Layout>
	);
};

PostList.propTypes = {
	data: PropTypes.object.isRequired,
	edges: PropTypes.array,
};

export default PostList;

export const postQuery = graphql`
	{
		allWordpressPost {
			edges {
				node {
					title
					featured_media {
						alt_text
						source_url
					}
					excerpt
					wordpress_id
					slug
				}
			}
		}
	}
`;
