import React from "react";

import { Link } from "gatsby";

import styled from "styled-components";

const Post = styled.div`
	display: flex;
	flex-flow: row wrap;
	width: 30%;
	height: 600px;
	box-sizing: border-box;
	margin: 1rem;
	box-shadow: 0px 6px 30px rgba(0, 0, 0, 0.2);

	.postImage {
		width: 100%;
		height: 300px;
		img {
			display: block;
			width: 100%;
			margin: 0 auto;
		}
	}

	.postContent {
		width: 100%;
		padding-left: 0.9rem;
		padding-right: 0.9rem;
		background-color: #fff;

		a {
			color: #000;
		}

		h4 {
			line-height: 1.4;
			font-family: "TrajanPro-Bold", sans-serif;
			margin-bottom: 0.7rem;
		}

		p {
			height: 4.8rem;
			line-height: 1.2;
			overflow-x: hidden;
			overflow-y: hidden;
			text-overflow: ellipsis;
			font-family: "GillSans", "sans";
			color: #393939;
		}
	}

	.blog_link {
		display: block;
		width: 50%;
	}

	@media (max-width: 960px) {
		width: 45%;
		height: 100%;
		.postImage {
			width: 100%;
			height: 100%;
		}
		.blog_link {
			margin: 0 auto;
			margin-top: 0.5rem;
			margin-bottom: 0.5rem;
		}
	}

	@media (min-width: 1024px) and (max-width: 1280px) {
		height: 500px;
		.postImage {
			height: 250px;
		}
	}
	@media (min-width: 481px) and (max-width: 768px) {
		width: 43.5%;
		.postContent {
			h4 {
				margin-top: 0.7rem;
				font-size: 0.9rem;
			}

			p {
				font-size: 14px;
			}
		}
	}

	@media (min-width: 320px) and (max-width: 480px) {
		width: 100%;
		.postContent {
			h4 {
				margin-top: 0.7rem;
				font-size: 0.9rem;
			}

			p {
				font-size: 14px;
			}
		}
	}

	@media (max-width: 320px) {
		.postContent {
			h4 {
				margin-top: 0.7rem;
				font-size: 0.9rem;
			}

			p {
				font-size: 12px;
			}
		}
	}
`;

const Button = styled.div`
	display: flex;
	width: 100%;
	justify-content: center;
	align-content: center;
	padding: 1rem;
	color: #000;
	cursor: pointer;
	text-transform: uppercase;
	text-align: center;
	text-decoration: none;
	position: relative;
	margin: 0 auto;
	font-weight: 600;
	background: rgb(247, 209, 134);
	background: radial-gradient(
		circle,
		rgba(247, 209, 134, 1) 0%,
		rgba(248, 211, 138, 1) 31%,
		rgba(224, 169, 74, 1) 78%
	);

	@media (min-width: 320px) and (max-width: 480px) {
		padding: 0.8rem;
		font-size: 14px;
	}

	@media (max-width: 320px) {
		padding: 0.5rem;
		font-size: 12px;
	}
`;

const BlogListCard = (props) => {
	return (
		<Post>
			<div className="postImage">
				<Link to={props.blogLink}>
					<img src={props.blogImage} alt={props.blogImageAlt} />
				</Link>
			</div>
			<div className="postContent">
				<Link to={props.blogLink}>
					<h4 dangerouslySetInnerHTML={{ __html: props.blogTitle }} />
				</Link>
				<p dangerouslySetInnerHTML={{ __html: props.blogExcerpt }}></p>
			</div>
			<div className="blog_link">
				<Link to={props.blogLink}>
					<Button>Read More</Button>
				</Link>
			</div>
		</Post>
	);
};

export default BlogListCard;
